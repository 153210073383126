@import url('https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap');
$text: #7C86A0;
$green: #00CBA1;

* {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Proto';
    src: url('Proto Mono Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Osake';
    src: url('Osake.ttf') format('truetype');
}

.tw-w-30 {
    width: 140px !important;
}

.tw-rounded-box {
    font-family: "Proto", sans-serif !important;
    border-radius: 0 !important;
    border: 1px solid #7C86A0 !important;
}

html {
    font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Syncopate', sans-serif;
    font-weight: bold;
}

.app {
    // background-image: url('../../public/images/swap-cover.png');
    background-color: #090909;
    min-height: 100vh;
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;

    .saturn {
        position: fixed;
        bottom: 20px;
        left: 125px;
        z-index: 1;
        width: auto;
    }
}

button,
a {
    cursor: pointer;
}

a {
    text-decoration: none;
}

// .sidebar{
//     padding: 0 20px;
//     overflow-x: hidden;
//     // background: rgba(14, 14, 14, 0.8);
//     background-image: url('../../public/images/sidebar-background.png');
//     background-position: center;
//     background-size: cover;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     position: fixed;
//     height: 100%;
//     width: 240px;
//     left: 0;
//     top: 0;
//     z-index: 9999;
//     transition: 0.4s ease-in-out;
//     .top{
//         padding: 20px 0;
//         display: flex;
//         align-items: center;
//         span{
//             font-family: 'Syncopate', sans-serif;
//             font-weight: bold;
//             margin-left: 10px;
//         }
//     }
//     .menu-icon{
//         padding: 20px 0;
//         .collapse-menu-btn{
//             background: transparent;
//             border: none;
//             cursor: pointer;
//         }
//     }
//     .menu-items{
//         display: flex;
//         flex-direction: column;
//         .nav-item{
//             display: flex;
//             border: none;
//             background: transparent;
//             align-items: center;
//             cursor: pointer;
//             text-decoration: none;
//             span{
//                 margin-left: 15px;
//                 text-transform: uppercase;
//                 color: $text;
//                 font-weight: 700;
//                 font-size: 15px;
//                 line-height: 19px;
//                 img{
//                     margin-left: 15px;
//                 }
//             }
//         }
//         .nav-link{
//             display: block;
//             background: #242628;
//             padding: 10px;
//             text-align: center;
//             margin: 10px 0;
//             border-radius: 50%;
//             img{
//                 max-width: 20px;
//             }
//         }
//         .active-menu{
//             .active{
//                 .nav-link{
//                     position: relative;
//                     &::before{
//                         content: '';
//                         position: absolute;
//                         width: 40px;
//                         height: 40px;
//                         left: 0px;
//                         top: 12px;
//                         background: linear-gradient(92.13deg, #944DFF 0.33%, #00CBA1 100.07%);
//                         filter: blur(12px);
//                         z-index: -1;
//                     }
//                 }
//                 span{
//                     color: white;
//                 }
//             }
//         }
//     }
//     .menu-icon{
//         .gradient-btn{
//             color: white;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 100%;
//             border: none;
//             padding: 10px 27px;
//             cursor: pointer;
//             span{
//                 margin-left: 5px;
//                 text-transform: uppercase;
//                 font-weight: bold;
//             }
//         }
//         .social-icons{
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             padding: 20px 0;
//         }
//     }
// }

// .sidebar.collapse{
//     width: 50px;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     align-items: center;
//     .menu-items, .top{
//         span{
//             display: none;
//         }
//     }
//     .gradient-btn,.social-icons{
//         display: none;
//     }
//     .collapse-menu-btn{
//         display: block;
//     }
//     &:hover{
//         width: 220px;
//         .menu-items, .top{
//             span{
//                 display: block;
//             }
//         }
//         .gradient-btn, .social-icons{
//             display: flex;
//         }
//         .collapse-menu-btn{
//             display: none;
//         }
//     }
// }

.main-content {
    margin-left: 50px;
}

.main {
    min-height: 100vh;
}

.text-gradient {
    background: linear-gradient(134.08deg, #ba37db 28.17%, #FFFFFF 71.73%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.gradient-btn {
    background: linear-gradient(92.13deg, #944DFF 0.33%, #00CBA1 100.07%);
    border-radius: 16px;
}

.top {
    position: relative;

    .mobile-menu-close-icon {
        position: absolute;
        display: none;
        right: 0;
        top: 25px;
        z-index: 9999;
        background: transparent;
        cursor: pointer;
        border: none;

        img {
            max-width: 15px;
        }
    }
}

.mobile-menu-icon {
    display: none;
    background: transparent;
    position: fixed;
    right: 10px;
    top: 20px;
    border: none;
    cursor: pointer;

    img {
        max-width: 40px;
    }
}

.sidebar.mobile-menu {
    width: 240px;
    // background: rgba(14, 14, 14, 0.8);
    background-image: url('../../public/images/sidebar-background.png');
    left: -100%;
    transition: 0.4s ease-in-out;
}

.sidebar.mobile-menu.active {
    left: 0 !important;
}

::-webkit-scrollbar {
    background: #1B1C1E;
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: #944DFF;
    width: 8px;
    border-radius: 3px;
    height: 8px;
}

.swap,
.liquidity,
.pool {
    .main {
        display: flex;
        justify-content: center;
        align-items: center;

        .card {
            padding: 32px;
            background: #242628;
            border-radius: 16px;
            min-height: 300px;

            .card-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
            }

            h2 {
                text-transform: uppercase;
                margin-bottom: 8px;
                font-size: 32px;
            }

            p {
                color: $text;
                font-size: 12px;
            }

            img {
                cursor: pointer;
                transition: 0.3s ease-in-out;
            }

            .card-body {
                margin: 25px 0;
                display: flex;
                flex-direction: column;
                gap: 16px;

                .box {
                    background: #1B1C1E;
                    border-radius: 16px;
                    padding: 24px;
                    position: relative;
                }

                .currency-dropdown {
                    width: 100%;
                    z-index: 9999;
                    min-height: 100px;
                    border-radius: 16px;
                    padding: 10px 0;
                    background: #1B1C1E;

                    .search-box {
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        padding: 12px 10px;
                        background: #242628;

                        input {
                            background: transparent;
                            width: 100%;
                            border: none;
                            padding: 0 10px;
                            color: $text;
                            font-size: 14px;
                            min-width: 250px;

                            &:focus {
                                outline: 0;
                            }
                        }
                    }

                    .text {
                        margin: 25px 0 10px 0;
                        display: flex;
                        align-items: center;
                        font-size: 15px;

                        span {
                            margin-left: 5px;
                        }
                    }

                    .btn-group {
                        display: flex;
                        overflow-x: auto;
                        gap: 10px;

                        .btn {
                            display: flex;
                            align-items: center;
                            padding: 8px 10px;
                            background: #242628;
                            border-radius: 16px;
                            border: none;

                            img {
                                max-width: 30px;
                            }

                            span {
                                color: white;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 19px;
                                margin-left: 5px;
                            }
                        }

                        .btn.disable {
                            opacity: 0.5;
                        }
                    }

                    .line {
                        width: 100%;
                        height: 1px;
                        margin: 20px 0;
                    }

                    table {
                        .first-col {
                            display: flex;
                            align-items: flex-start;
                            max-width: 30px;

                            h5 {
                                color: white;
                                text-transform: uppercase;
                                font-weight: bold;
                            }

                            div {
                                margin-left: 10px;
                                margin-top: 5px;
                            }

                            p {
                                color: $text;
                                font-size: 11px;
                                margin-top: 3px;
                                text-align: left;
                            }
                        }

                        p {
                            color: white;
                            font-size: 15px;
                            text-align: right;
                        }

                        td {
                            padding: 10px 0;
                        }
                    }
                }

                .below-currency-box {
                    margin-top: -35px;

                    .currency-dropdown {
                        top: 75px;
                    }
                }

                .top {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    h4 {
                        font-weight: normal;
                        text-transform: uppercase;
                        color: $text;
                        font-size: 14px;
                        letter-spacing: 1px;

                        span {
                            color: $green;
                            font-weight: 600;
                        }
                    }

                    .below-currency,
                    .below-currency-data {
                        margin-top: 0 !important;
                    }

                    .left {
                        width: 60%;

                        .row {
                            margin-top: 20px;

                            h3 {
                                text-transform: uppercase;
                                margin-bottom: 3px;

                                span {
                                    img {
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .right {
                        .above-currency-box {
                            margin-top: 15px;
                        }

                        .data {
                            text-align: right;

                            h4 {
                                font-weight: bold;
                                font-size: 20px;
                                margin-bottom: 5px;
                            }
                        }

                        .buttons {
                            display: flex;

                            button {
                                border: none;
                                width: 40px;
                                height: 23px;
                                background: #242628;
                                border-radius: 8px;
                                margin: 0 5px;
                                color: $text;
                                font-size: 9.5px;
                                text-transform: uppercase;
                                transition: 0.3s ease-in-out;

                                &:hover {
                                    background: #944DFF;
                                    color: white;
                                }
                            }
                        }
                    }
                }

                .swap-icon {
                    text-align: center;
                    margin-top: -30px;
                    z-index: 10;

                    img {
                        max-width: 40px;
                        padding: 10px;
                        border-radius: 50%;
                        background: #242628;
                        box-shadow: 0px 20px 20px rgb(0 0 0 / 5%);
                    }
                }

                .last-box {
                    h3 {
                        text-transform: uppercase;
                        font-size: 15px;
                        font-weight: 500;
                        font-family: 'Inter', sans-serif;
                    }

                    .currency-dropdown {
                        top: 45px;

                        .currency-data {
                            display: flex;
                            justify-content: space-between;
                            margin: 10px 0;

                            p {
                                font-size: 13px;
                                color: $text;
                            }

                            .right-data {
                                text-align: right;
                            }
                        }

                        .line-border {
                            width: 100%;
                            height: 2px;
                            background: #242628;
                            border-radius: 1px;
                            margin: 20px 0;
                        }
                    }

                    .top {
                        .right {
                            text-align: right;
                        }
                    }
                }

                .submit-btn {
                    margin-top: 25px;

                    button {
                        color: white;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        border: none;
                        padding: 16px 27px;
                        cursor: pointer;

                        span {
                            margin-left: 5px;
                            text-transform: uppercase;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

    .settings {
        position: absolute;
        right: 0;
        top: 50px;
        z-index: 999;
        width: 91%;
        height: auto;
        background: #292B2E;
        box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        padding: 25px;

        h4 {
            font-weight: 700;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: $text;
        }

        p {
            color: $text;
            font-size: 14px !important;
            margin: 20px 0 10px 0;
            display: flex;
            align-items: center;
            font-weight: 500;

            span {
                margin-left: 10px;
            }
        }

        .bottom-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;

            button {
                border: none;
                padding: 6px 16px;
                background: #1B1C1E;
                color: white;
                font-size: 16px;
                border-radius: 16px;
                font-weight: bold;
                transition: 0.3s ease-in-out;

                &:hover {
                    background: #944DFF;
                }
            }

            button.active {
                background-color: #944DFF;
            }

            .last {
                padding: 6px 16px;
                background: #1B1C1E;
                color: white;
                font-size: 16px;
                border-radius: 16px;
                font-weight: bold;
                color: #373B47;
                border: none;
                width: 90px;
                text-align: right;

                &:focus {
                    outline: none;
                }
            }
        }
    }

    .arrow.active {
        img {
            transform: rotate(-180deg);
        }
    }
}

.card-body {
    .order-col-0 {
        order: 0;
    }

    .order-col-1 {
        order: 1;
    }

    .order-col-2 {
        order: 2;
    }

    .order-col-3 {
        order: 3;
    }
}

.swap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    // padding: 15px 0;
    .right {
        .data {
            margin-top: 20px;
        }
    }
}

/* For PC: */
// .swap-padding {
//     margin-top: 40px;
// }

/* For mobile: */
@media only screen and (max-width: 600px) {
    // .swap-padding {
    //     margin-top: 100px;
    // }
}

.liquidity {
    padding: 100px 0 30px 0;

    .card {
        .card-top {
            h2 {
                margin-bottom: 0;
                font-size: 24px;
            }
        }

        .below-currency-box {
            margin-top: 0 !important;
        }

        .left {
            .row {
                margin-top: 0 !important;
            }

            width: 60%;
        }

        .above-currency-box {
            .data {
                margin-top: 0;
            }
        }

        .first-box {
            p {
                line-height: 21px;
            }
        }

        .last-box {
            h4 {
                text-transform: uppercase;
                color: $text !important;
                font-size: 17px;
                letter-spacing: 1px;
                padding-bottom: 20px;
                border-bottom: 2px solid #242628;
                margin-bottom: 15px;
            }

            .col-sm-4 {
                text-align: center;
                margin-top: 20px;

                h3 {
                    font-size: 14px !important;
                    font-family: 'Syncopate', sans-serif !important;
                    font-weight: bold !important;
                }

                p {
                    font-size: 10.5px !important;
                    margin: 5px 0;
                }
            }
        }
    }

    .star-box {
        text-align: center;
        margin-top: 100px;
        padding: 20px;

        p {
            color: $text;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            margin-top: 15px;
        }
    }
}

.pool {
    padding: 60px 0;

    .card-top {
        flex-wrap: wrap;

        h2 {
            margin-bottom: 0;
            font-size: 24px !important;
        }

        .right-top {
            flex-wrap: wrap;
        }
    }

    .last-box {
        text-align: center;
        padding: 80px 25px !important;

        .gradient-btn {
            color: white;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            padding: 16px 27px;
            cursor: pointer;
            margin: auto;

            span {
                margin-left: 5px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        h4 {
            margin-top: 20px;
            text-transform: uppercase;
            font-weight: bold;
            font-family: 'Syncopate', sans-serif;
        }

        p {
            margin: 12px 0 25px 0;
        }
    }

    .below-currency-box {
        margin-top: 0 !important;
    }

    .left {
        .row {
            margin-top: 0 !important;
        }

        width: 60%;
    }

    .right {
        .data {
            margin-top: 0 !important;
        }
    }

    .import-btn {
        margin-top: 25px;
        padding: 0 !important;

        button {
            color: $text;
            display: flex;
            background-color: transparent;
            justify-content: center;
            align-items: center;
            width: 100%;
            border: none;
            padding: 16px 27px;
            cursor: pointer;

            span {
                margin-left: 5px;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }
}

.assets {
    .right-top {
        display: inline-flex;

        .inner-box {
            background: #1B1C1E;
            border-radius: 10px;
            display: flex;
            align-items: center;
            padding: 12px 10px;

            input {
                background: transparent;
                width: 100%;
                border: none;
                padding: 0 10px;
                color: $text;
                font-size: 14px;
                min-width: 250px;

                &:focus {
                    outline: 0;
                }
            }
        }

        .filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 10px;
            min-width: 100px;
            margin-left: 10px;
            cursor: pointer;

            .arrow-img {
                max-width: 12px;
            }
        }
    }

    .top-col {
        padding: 25px 0;
        gap: 15px;
        margin: auto;

        .col {
            background: rgba(27, 28, 30, 0.6);
            backdrop-filter: blur(10px);
            border-radius: 8px;
            padding: 15px 25px;

            p {
                color: $text;
                font-size: 13px;
                text-transform: capitalize;
                margin: 10px 0;
            }

            h3 {
                margin-bottom: 10px;
            }
        }
    }

    .card {
        .line {
            width: 100%;
            height: 1px;
            margin: 20px 0;
        }

        .card-body {
            table {
                text-align: left;
                border-spacing: 0 15px;

                tr,
                td,
                th {
                    padding: 10px 10px;
                }

                tbody {
                    tr {
                        background: #1B1C1E;
                        border-radius: 8px;
                    }

                    td {
                        margin: 10px 0;

                        .first-row-data {
                            display: flex;

                            .details {
                                margin-left: 10px;
                                margin-top: 3px;

                                h3 {
                                    text-transform: uppercase;
                                }

                                p {
                                    text-transform: uppercase;
                                    margin-top: 5px;
                                }
                            }
                        }

                        .text-gradient.data {
                            font-weight: bold;
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        .card-bottom {
            text-align: center;
        }
    }
}

.my-liquidity {
    h1 {
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    .card-top {
        flex-wrap: wrap;
    }

    .left-top {
        display: flex;
        gap: 10px;
        width: 48%;
        justify-content: space-between;

        button {
            background: #1B1C1E;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 16px;
            border: none;
            border-radius: 8px;

            span {
                font-size: 11px;
                font-weight: bold;
                margin-left: 5px;
                text-transform: uppercase;
            }
        }

        .create-btn {
            background: #944DFF;
        }
    }

    .right-top {
        width: 48%;
    }

    .card-body {
        .body-content {
            text-align: center;
            padding-top: 70px;
            padding-bottom: 30px;

            h3 {
                text-transform: uppercase;
                margin-top: 15px;
            }

            p {
                color: $text;
                margin: 5px 0;
            }

            .gradient-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
                border: none;
                padding: 12px 30px;
                margin-top: 20px;
                border-radius: 8px;
                cursor: pointer;

                span {
                    color: white;
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }
    }

    .bottom {
        margin-top: 30px;

        .line {
            width: 100%;
            height: 1px;
            margin: 20px 0;
        }

        .bottom-content {
            padding: 20px 20px 10px 20px;
            background: rgba(36, 38, 40, 0.6);
            backdrop-filter: blur(10px);
            border-radius: 10px;
            height: 100%;

            h2 {
                font-size: 26px;
                line-height: 40px;
                text-transform: uppercase;
            }

            p {
                color: white;
                line-height: 25px;
                font-size: 13px;
                margin-bottom: 20px;
            }
        }
    }
}

.not-found {
    min-height: 100vh;

    .main-content {
        margin-left: 0;
    }

    .main {
        display: flex;
        justify-content: center;
        align-items: center;

        .card {
            background: rgba(36, 38, 40, 0.6);
            backdrop-filter: blur(10px);
            border-radius: 10px;
            padding: 50px 24px;
            text-align: center;

            h1 {
                font-size: 30px;
                color: white;
                text-transform: uppercase;

                span {
                    color: rgb(255, 48, 48) !important;
                }
            }

            p {
                margin: 20px 0 10px 0;
                color: $text;
            }

            .gradient-btn {
                padding: 10px 30px;
                color: white;
                margin: 20px 0;
                border-radius: 8px;
                display: inline-block;
            }
        }
    }
}

.menu-bar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    // min-height: 30px;
    border-bottom: 1px solid #7C86A0;
}

@media only screen and (max-width: 600px) {
    .menu-bar {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        // padding-top: 20px;
    }
}

.logo {
    padding: 11px 40px;
    position: relative;
    width: 52px;
    height: 52px;
}

@media only screen and (max-width: 600px) {
    .logo {
        padding-left: 18px;
        position: relative;
        width: 40px;
        height: 40px;
        padding-bottom: 7px;
    }
}

.mobile_title {
    display: none;
}

@media only screen and (max-width: 600px) {
    .mobile_title {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        font-size: 24px;
        font-family: "Osake", sans-serif !important;
        text-transform: uppercase;
        color: #FFFFFF;
    }
}

.desktop_title {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "Osake", sans-serif !important;
    font-size: 42px;
}

@media only screen and (max-width: 600px) {
    .desktop_title {
        display: none;
    }
}

// .title {
//     text-transform: uppercase;
//     color: #FFFFFF;
//     font-family: "Osake", sans-serif !important;
//     font-size: 42px;
//     // padding-top: 14px;
// }

// @media only screen and (max-width: 600px) {
//     .title {
//         text-transform: uppercase;
//         color: #FFFFFF;
//         font-family: "Osake", sans-serif !important;
//         font-size: 24px;
//         padding-left: 50px;
//         // padding-top: 14px;
//     }
// }

.switch-button {
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: "Osake", sans-serif !important;
    // font-weight: bold;
    width: 160px;
    font-size: 22px;
    padding-right: 40px;
    z-index: 10;
}

@media only screen and (max-width: 600px) {
    .switch-button {
        display: flex;
        justify-content: end;
        font-size: 14px;
        padding: 20px;
        // padding-top: 14px;
    }
}

.footer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    background-color: #000000;
    padding: 14.5px 55px;
    border-top: 1px solid #7C86A0;
}

@media only screen and (max-width: 600px) {
    .footer {
        padding: 14px 18px;
        align-items: center;
    }
}

.footer-copyright {
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
    font-family: "Osake", sans-serif !important;
}

@media only screen and (max-width: 600px) {
    .footer-copyright {
        font-size: 14px;
        text-align: center;
    }
}

.footer-social-icons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 20px;
}

.footer-tg-ico {
    width: 22px;
    height: 22px;
}

.footer-twitter-ico {
    width: 18px;
    height: 18px;
}

.tw-rounded-dropdown {
    border: 1px solid #7C86A0 !important;
}

.tw-rounded-btn {
    border: 1px solid #7C86A0 !important;
}

.tw-group {
    border: 1px solid #7C86A0 !important;
}

@import './responsive.scss'